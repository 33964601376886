import React, { useState } from "react";
import { Button, colors } from "@cimpress/react-components";
import { useAppSelector } from "../../store/storeHooks";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { AuthContext } from "../../context/authContext";
import DataProductCustomFieldEditable from "./customFields/edit/DataProductCustomFieldEditable";
import DataProductCustomFieldView from "./customFields/view/DataProductCustomFieldView";
import AddCustomFieldModal from "./customFields/add/AddCustomFieldModal";
import { useAppDispatch } from "../../store/storeHooks";
import { cleanStatusAndErrors, setAddStatus } from "@cimpress-technology/data-portal-core/lib/features/customFields/customFieldsSlice";
import { addCustomField } from "@cimpress-technology/data-portal-core/lib/clients/dataPortalApi";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { useMemoizedLoaderForCustomFields } from "../../store/useCustomFields";
import { onSavePayload } from "./customFields/edit/UpdateCustomFieldBase";

type HeaderProps = {
    setAddCustomField: React.Dispatch<React.SetStateAction<boolean>>, 
    canEdit: boolean
}

const AdditionalInformationHeader = (props: HeaderProps) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: 'space-between'
            }}
        >
            <h3 style={{ color: colors.shale, marginTop: 0 }}>Additional Information</h3>
            {props.canEdit && 
                <Button
                    className="pull-right"
                    variant="primary"
                    onClick={() => props.setAddCustomField(true)}
                    size="sm"
                >
                    + Add Information
                </Button>
            }
        </div>
    );
};

type Props = { hasDataProductPermission: boolean; dataProductId: string };
const DataProductCustomFields = (props: Props) => {
    const { status, customFields } = useAppSelector(
        (state) => state.customFields
    );
    const dispatch = useAppDispatch();
    const { accessToken } = React.useContext(AuthContext);
    const [isAddingCustomField, setIsAddingCustomField] = useState<boolean>(false);
    const [customFieldAddValidationError, setCustomFieldAddValidationError] = useState<string>("");

    const canEdit = props.hasDataProductPermission && accessToken !== undefined;
    const fetchCustomFields = useMemoizedLoaderForCustomFields(accessToken, props.dataProductId);

    const addNewCustomField = async (payload: onSavePayload) => {
        try {
            dispatch(setAddStatus('loading'));
            await addCustomField(
                accessToken as string,
                props.dataProductId,
                payload
            ).then(() => {
                dispatch(setAddStatus('succeeded'));
                setIsAddingCustomField(false);
                // Dispatch an action to refresh customFields
                fetchCustomFields();
                setCustomFieldAddValidationError("");
            });
        } catch (error) {
            dispatch(setAddStatus('failed'));
            setCustomFieldAddValidationError(parseUnknownErrorTypeToErrorMessage(error));
        }
    };

    return (
        <div style={{ paddingBottom: 20 }}>
            <AdditionalInformationHeader setAddCustomField={setIsAddingCustomField} canEdit={canEdit} />
            {isAddingCustomField &&
                <AddCustomFieldModal
                    fnOnSave={addNewCustomField}
                    fnOnClose={() => {
                        dispatch(cleanStatusAndErrors());
                        setIsAddingCustomField(false);
                        setCustomFieldAddValidationError("");
                    }}
                    additionError={customFieldAddValidationError}
                    setAdditionError={setCustomFieldAddValidationError}
                />
            }
            <hr></hr>
            {status === "loading" && <Spinner size="small" />}
            {status === "succeeded" &&
                customFields &&
                Object.keys(customFields).map((key) =>
                    canEdit ? (
                        <DataProductCustomFieldEditable
                            key={key}
                            customField={customFields[key]}
                            accessToken={accessToken}
                            dataProductId={props.dataProductId}
                        />
                    ) : (
                        <DataProductCustomFieldView
                            key={key}
                            customFieldId={key}
                            customField={customFields[key]}
                        />
                    )
                )}
        </div>
    );
};

export default DataProductCustomFields;
