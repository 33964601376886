import React, { useState } from "react";
import { CustomField } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { IconPencilAlt } from "@cimpress-technology/react-streamline-icons/lib";
import { colors } from "@cimpress/react-components";
import { removeCustomFieldFromDataProduct, updateCustomFieldValueInDataProduct } from "@cimpress-technology/data-portal-core/lib/features/customFields/common";
import { useAppDispatch } from "../../../../store/storeHooks";
import { CustomFieldEditorFactory } from "./CustomFieldEditorFactory";
import IconBin from "@cimpress-technology/react-streamline-icons/lib/IconBin";
import ConfirmationAlert from "../../common/ConfirmationAlert";
import SanitizeHtmlContent from "../../common/SanitizeHtmlContent";

type Props = {
    customField: CustomField;
    accessToken: string;
    dataProductId: string;
};

function DataProductCustomFieldEditable(props: Props) {
    const dispatch = useAppDispatch();
    const [edit, setEdit] = useState<boolean>(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
    const updateValue = (value: string) => {
        dispatch(
            updateCustomFieldValueInDataProduct({
                accessToken: props.accessToken,
                dataProductId: props.dataProductId,
                customFieldId: props.customField.customFieldId,
                value: value,
            })
        ).then((response) => {
            if (response.meta.requestStatus === "fulfilled") {
                setEdit(false);
            }
        });
    };

    const deleteCustomField = () => {
        dispatch(
            removeCustomFieldFromDataProduct({
                accessToken: props.accessToken,
                dataProductId: props.dataProductId,
                customFieldId: props.customField.customFieldId
            })
        );
    };

    return (
        <div style={{ marginBottom: 40 }} key={props.customField.customFieldId}>
            <h5>
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                    <h5 style={{ fontWeight: 'bold' }}>{props.customField.displayName}</h5>
                    <IconBin
                        style={{
                            color: colors.danger.base,
                            height: "17px",
                            alignSelf: 'center',
                            cursor: 'pointer'
                        }}
                        weight="fill"
                        onClick={()=>setDeleteConfirmationOpen(true)}
                    />
                    <ConfirmationAlert
                        onCancel={()=>setDeleteConfirmationOpen(false)}
                        onConfirm={deleteCustomField}
                        message={<p>Are you sure you want to delete <strong>{props.customField.displayName}</strong> custom field from the Data Product</p>}
                        show={deleteConfirmationOpen}
                    />
                </div>
            </h5>
            {edit ? (
                <CustomFieldEditorFactory
                    customFieldId={props.customField.customFieldId}
                    displayName={props.customField.displayName}
                    value={props.customField.value}
                    fnOnSave={updateValue}
                    fnOnClose={() => setEdit(false)}
                />
            ) : (
                <div
                    style={{
                        display: "flex",
                        gap: 8,
                    }}
                >
                    <SanitizeHtmlContent value={props.customField.value} section="custom-field" sanitizeData={false}/>
                    <div style={{ minWidth: 20, alignSelf: 'center' }}>
                        <IconPencilAlt
                            weight="fill"
                            style={{ cursor: "pointer", height: 18 }}
                            color={colors.info.base}
                            size="sm"
                            onClick={() => setEdit(true)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default DataProductCustomFieldEditable;
