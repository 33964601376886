import { Button, Modal, shapes, Snackbar } from "@cimpress/react-components";
import { Clients, DataPortalAPI } from "@cimpress-technology/data-portal-core";
import React, { useState } from "react";
import axios from "axios";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../store/storeHooks";
import {
    fetchDataProductMembers,
    removeDataProductMember,
} from "@cimpress-technology/data-portal-core/lib/features/members/common";
import { cleanOperationStatusAndErrors } from "@cimpress-technology/data-portal-core/lib/features/members/membersSlice";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";

export const DeleteUserButton: React.FC<{
    accessToken: string;
    userInfo: {
        canonicalPrincipal: string;
        role: DataPortalAPI.DataProductMemberRole;
        isProductTeamMember: boolean;
        isDeletedFromCoam?: boolean;
    };
    dataProductId: string;
    onUserDeleted(key: string): void;
    disableDelete: boolean;
}> = ({
    accessToken,
    userInfo,
    dataProductId,
    onUserDeleted,
    disableDelete,
}) => {
    const { fieldError } = useAppSelector((state) => state.members);
    const dispatch = useAppDispatch();
    const [deletingError, setDeletingError] = useState(
        undefined as Error | undefined
    );
    const [deleting, setDeleting] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const deleteCompletely = async () => {
        setDeleting(true);
        setDeletingError(undefined);
        try {
            await Clients.DataPortalAPI.removeMembers(
                accessToken,
                dataProductId,
                userInfo.role,
                [userInfo.canonicalPrincipal]
            );
            onUserDeleted(userInfo.canonicalPrincipal);
        } catch (error) {
            setDeletingError(
                axios.isAxiosError(error)
                    ? error.response?.data?.message ||
                          error.response?.data ||
                          error.message
                    : error
            );
        }
        setDeleting(false);
        setShowModal(false);
    };

    const deleteOnlyFromProductTeam = async () => {
        setDeleting(true);
        dispatch(
            removeDataProductMember({
                accessToken,
                dataProductId,
                canonicalId: userInfo.canonicalPrincipal,
            })
        ).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                setShowModal(false);
            }
            setDeleting(false);
            if (userInfo.isDeletedFromCoam) {
                onUserDeleted(userInfo.canonicalPrincipal);
            } else {
                dispatch(
                    fetchDataProductMembers({
                        accessToken,
                        dataProductId,
                    })
                );
            }
        });
    };

    return (
        <>
            <Snackbar
                show={!!deletingError}
                status={"danger"}
                delay={4000}
                onHideSnackbar={() => setDeletingError(undefined)}
            >
                Error Deleting user.
                <br />
                {JSON.stringify(deletingError)}
            </Snackbar>
            <Snackbar
                show={fieldError[userInfo.canonicalPrincipal] != null}
                status={"danger"}
                delay={4000}
                onHideSnackbar={() => dispatch(cleanOperationStatusAndErrors())}
            >
                {fieldError[userInfo.canonicalPrincipal] != null &&
                    parseUnknownErrorTypeToErrorMessage(
                        fieldError[userInfo.canonicalPrincipal]
                    )}
            </Snackbar>
            <Modal
                show={showModal}
                size="lg"
                title={"Remove User"}
                onRequestHide={() => setShowModal(false)}
                closeButton={deleting !== true}
                footer={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            disabled={deleting === true}
                            onClick={deleteOnlyFromProductTeam}
                        >
                            Remove <b>only</b> from Product Team
                        </Button>
                        <Button
                            disabled={deleting === true}
                            onClick={deleteCompletely}
                        >
                            Remove Completely
                        </Button>
                    </div>
                }
            >
                <div>
                    <h3>
                        Do you want to remove{" "}
                        <b>{userInfo.canonicalPrincipal}</b> only from the
                        Product Team?
                    </h3>
                </div>
            </Modal>
            <Button
                size="sm"
                disabled={deleting || disableDelete}
                onClick={
                    userInfo.isProductTeamMember ?
                        userInfo.isDeletedFromCoam ?
                            deleteOnlyFromProductTeam
                            : () => setShowModal(true)
                        : deleteCompletely
                }
            >
                {deleting ? (
                    <span style={{ display: "flex" }}>
                        <shapes.Spinner size="small" />
                        &nbsp;Removing...
                    </span>
                ) : (
                    "Remove"
                )}
            </Button>
        </>
    );
};
