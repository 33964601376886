import React, { useRef, useState } from "react";
import { Modal, TextField } from "@cimpress/react-components";
import { Button } from "@cimpress/react-components";
import HtmlEditor from "../utils/TextEditor";
import { Editor as TinyMCE } from "tinymce";
import { renderError } from "../../Render";
import { useAppSelector } from "../../../../store/storeHooks";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { onSavePayload } from "../edit/UpdateCustomFieldBase";

/**
 * AddCustomFieldModal component renders a modal for adding a custom field with a display name and HTML content.
 * 
 * @param {Object} props - The properties object.
 * @param {Function} props.fnOnSave - The function to call when the custom field is saved. It receives an object with `displayName` and `value` properties.
 * @param {Function} props.fnOnClose - The function to call when the modal is closed.
 * @param {Function} props.setAdditionError - The function to set the addition error message.
 * @param {string} props.additionError - The error message to display if there is an addition error.
 * 
 * @returns {JSX.Element} The rendered AddCustomFieldModal component.
 */

type Props = { 
    fnOnSave: (payload: onSavePayload) => void;
    fnOnClose: () => void;
    setAdditionError: (error: string) => void;
    additionError: string;
};

const AddCustomFieldModal = (props: Props) => {
    const editorRef = useRef<TinyMCE | null>(null);
    const [displayName, setDisplayName] = useState<string>("");
    const { addStatus } = useAppSelector(
        (state) => state.customFields
    );

    const onCreate = () => {
        if(validate())
            if (editorRef.current) {
                props.fnOnSave({
                    displayName: displayName,
                    value: editorRef.current.getContent()
                });
            }
    };

    const validate = () => {
        if(displayName.length>0 && editorRef.current && editorRef.current.getContent().length>0) {
            props.setAdditionError("");
            return true;
        }
        props.setAdditionError("Display name and content are required.");
        return false;
    };

    return (
        <Modal
            status="info"
            show={true}
            title={
                <TextField
                    type="text"
                    style={{ fontSize: '60%', padding: "0px 10px" }}
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    placeholder="Enter Display Name"
                />
            }
            onRequestHide={props.fnOnClose}
            closeButton={true}
            closeOnOutsideClick={true}
            size="lg"
            footer={
                <div>
                    <Button
                        variant="outline-secondary"
                        onClick={props.fnOnClose}
                        disabled={addStatus === "loading"}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onCreate}
                        disabled={addStatus === "loading"}
                    >
                        {addStatus === "loading" ? (
                            <Spinner size="small" />
                        ) : (
                            "Add"
                        )}
                    </Button>
                </div>
            }
        >
            <HtmlEditor
                editorRef={editorRef}
                content={""}
                disabled={addStatus === "loading"}
            />
            {props.additionError.length>0 &&
                renderError(
                    props.additionError 
                )}
        </Modal>
    );
};
export default AddCustomFieldModal;