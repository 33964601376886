import { Config } from "dompurify";
import React from "react";
import DOMPurify from 'dompurify';

type Props = { value: string, section: string, sanitizeData: boolean, sanitizationTags?: Config };

const SanitizeHtmlContent = (props: Props): JSX.Element => {
    const { value, section, sanitizeData, sanitizationTags } = props;
    let renderedValue = value;
    if (!renderedValue || renderedValue.length === 0) renderedValue = '-- --';
    return (
        <div
            className={`summary__${section}__item__value summary-tiny-mce-content-container`}
            id={`${section}-value-container`}
            role="presentation"
            dangerouslySetInnerHTML={{ __html: sanitizeData ? DOMPurify.sanitize(renderedValue, sanitizationTags) : renderedValue }}
        ></div>
    );
};

export default SanitizeHtmlContent;
