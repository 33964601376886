import React, { useState } from 'react';
import { colors } from '@cimpress/react-components';
import { Interfaces, Hooks } from "@cimpress-technology/data-portal-core";
import { AuthContext } from '../../../context/authContext';
import { renderLoading, renderError } from '../../shared/Render';
import { DataProductComponentsSummary } from '../../shared/DataProductComponentsSummary';
import DataProductCustomFields from '../../shared/DataProductCustomFields';
import { useDomains } from '@cimpress-technology/data-portal-core/lib/hooks';
import { DataProductDomain } from './sections/DataProductDetails/domain';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import useProductTeam from '../../../hooks/useProductTeam';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { isErrorUserInformation, isLoadingUserInformation, isValidUserInformation } from '@cimpress-technology/data-portal-core/lib/features/coamUserInfoCache/coamUserInfoCacheSlice';
import ContactInformation from './sections/DataProductDetails/ContactInformation';
import { ExtendedDataProduct } from '../../../clients/dataPortalApi';
import { patchDataProductDocumentation } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalApi';
import { getDefaultAvatar } from '../../../common';
import URLEditor from '../../shared/URLEditor';
import { PatchOperation } from '@cimpress-technology/data-portal-core/lib/interfaces/common';
import { ManageResourceControlled } from './sections/DataProductDetails/ManageResourceControlled';
import DescriptionModal from './sections/DataProductDetails/DescriptionModal';
import { IconPencilAlt } from "@cimpress-technology/react-streamline-icons/lib";
import SanitizeHtmlContent from '../../shared/common/SanitizeHtmlContent';
import { AutoAcceptForAllContracts } from './sections/DataProductDetails/AutoAcceptForAllContracts';

export const DataProductDetailsSection: React.FC<{
    dataProduct: Interfaces.DataPortalAPI.DataProduct,
    domain: Interfaces.DataPortalAPI.Domain,
    selectedDomainId:string | undefined,
    setSelectedDomainId:React.Dispatch<React.SetStateAction<string|undefined>>,
    hasDataProductPermission: boolean,
    setDataProduct: React.Dispatch<React.SetStateAction<ExtendedDataProduct | null>>,
    inlineUpdateError?: Error | null,
    loadingInlineUpdate?: { dataProductName: boolean, summary: boolean },
    updateAttributeFunction?: (accessToken: string, dataProductId: string, attributeName: string, value: string | boolean) => Promise<boolean>,
    databricksUnits: { type: DataPortalAPI.DataResourceType; name: string; }[],
    dbtUnits: { type: DataPortalAPI.DataResourceType; name: string; }[],
}> = ({ dataProduct, domain, selectedDomainId, setSelectedDomainId, hasDataProductPermission, setDataProduct, updateAttributeFunction, inlineUpdateError, loadingInlineUpdate, databricksUnits, dbtUnits }) => {

    const { accessToken, profile } = React.useContext(AuthContext);
    const { accountDetails, loadingAccount, accountError, loadAccount } = Hooks.useAccount(accessToken, domain?.accountId);
    const domainHook = useDomains(accessToken, profile?.['https://claims.cimpress.io/account']);
    const { productTeam, status: productTeamStatus, error: productTeamError } = useProductTeam({ accessToken, dataProductId: dataProduct.dataProductId });
    const [editDescriptionModal, setEditDescriptionModal] = useState<boolean>(false);

    const {
        summary,
        published,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
    } = dataProduct;

    return <div>
        <div className='row' style={{marginBottom: 16}}>
            <div className='col-md-8'>
                <h3 style={{ color: colors.shale }}>Overview</h3>
                <hr />
                <div>
                    {
                        <div style={{ display: "block", minHeight: 60 }}>
                            <div className="editor-label">Description</div>
                            <div className='col-12' style={{ display: 'flex', gap: 8 }}>
                                <div data-testid="dpt-summary" className="data-product-description-view">
                                    <SanitizeHtmlContent
                                        value={summary}
                                        section='summary-description'
                                        sanitizeData={false}
                                    />
                                </div>
                                <div style={{ minWidth: 20, alignSelf: 'center' }}>
                                    <IconPencilAlt
                                        weight="fill"
                                        style={{ cursor: "pointer", height: 18, display: !(hasDataProductPermission && updateAttributeFunction) && 'none' }}
                                        color={colors.info.base}
                                        size="sm"
                                        onClick={() => setEditDescriptionModal(true)}
                                    />
                                </div>
                                <DescriptionModal
                                    isLoading={loadingInlineUpdate?.summary}
                                    onUpdate={async (updatedValue: string) => {
                                        if (accessToken && updateAttributeFunction){
                                            await updateAttributeFunction(accessToken, dataProduct.dataProductId, "summary", updatedValue);
                                            setEditDescriptionModal(false);
                                        }
                                    }}
                                    error={inlineUpdateError}
                                    value={summary}
                                    isOpen={editDescriptionModal}
                                    onClose={()=>setEditDescriptionModal(false)}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className="editor-label" style={{marginTop: 30}}>Additional Documentation</div>
                {accessToken && (dataProduct.documentation || []).length === 0 && (
                    <URLEditor
                        key={-1}
                        hasPermission={hasDataProductPermission}
                        updateFunction={async (value: { url: string, title: string }) => {
                            const afterAdd = await patchDataProductDocumentation(PatchOperation.Add, accessToken, dataProduct.dataProductId, `/documentation/-`, value);
                            setDataProduct(afterAdd);
                        }}
                        url={{url: '', title: ''}}
                        allowDelete={false}
                    />
                )}
                {accessToken && dataProduct.documentation?.map((doc, i) =>
                    <URLEditor
                        key={i}
                        hasPermission={hasDataProductPermission}
                        updateFunction={async (value: { url: string, title: string }) => {
                            const afterAdd = await patchDataProductDocumentation(PatchOperation.Add, accessToken, dataProduct.dataProductId, `/documentation/-`, value);
                            try {
                                const afterRemove = await patchDataProductDocumentation(PatchOperation.Remove, accessToken, dataProduct.dataProductId, `/documentation/${i}`);
                                setDataProduct(afterRemove);
                            } catch (e) {
                                setDataProduct(afterAdd);
                                throw e;
                            }
                        }}
                        url={doc.title ? {url: doc.url, title: doc.title} : {url: doc.url, title: doc.url}}
                        allowDelete={true}
                        deleteFunction={async () => {
                            const afterRemove = await patchDataProductDocumentation(PatchOperation.Remove, accessToken, dataProduct.dataProductId, `/documentation/${i}`);
                            setDataProduct(afterRemove);
                        }}
                    />
                )}
                <br /><br />

                <DataProductCustomFields hasDataProductPermission={hasDataProductPermission} dataProductId={dataProduct.dataProductId} />

                <br />

                {
                    hasDataProductPermission
                        ? <DataProductComponentsSummary
                            inputPorts={dataProduct.inputPorts?.map(o => ({ type: o.portType, name: o.resourceId })) || []}
                            resources={dataProduct.resources?.map(o => ({ type: o.resourceType, name: o.resourceId })).concat(databricksUnits).concat(dbtUnits) || []}
                            outputPorts={(dataProduct.outputPorts || []).map(p => {
                                const resourceId = p.resourceId.split("/").length === 4 ? p.resourceId.split("/")[3] : p.resourceId;
                                return { type: p.portType, name: resourceId };
                            })}  // Extracting dataset name
                        />
                        : null
                }

            </div>
            <div className='col-md-offset-1 col-md-3'>
                <h3>Details</h3>
                <hr />
                {
                    hasDataProductPermission
                        ? <>
                            <div className={`stat stat-${published ? 'success' : 'danger'}`}>
                                <h6>
                                    Status
                                </h6>
                                <span className="stat-value">{published ? 'Published' : 'Not published'}</span>
                            </div>
                            <br /><br />
                        </>
                        : null
                }
                <div className="stat stat-info">
                    <h6>Domain</h6>
                    <DataProductDomain
                        dataProductId={dataProduct.dataProductId}
                        domainHook={domainHook}
                        currentDomain={domain}
                        isDataProductAdmin={hasDataProductPermission}
                        selectedDomainId={selectedDomainId }
                        setSelectedDomainId={setSelectedDomainId}
                    />
                </div>

                <br /><br />

                <div className="stat stat-info">

                    <h6>Account</h6>
                    <div className="stat-value">
                        {
                            loadingAccount
                                ? renderLoading('Loading business', false, 'small')
                                : accountError
                                    ? renderError(`Error loading business`, accountError, loadAccount)
                                    : <span style={{ color: colors.shale }}>{accountDetails?.name}</span>
                        }
                    </div>

                </div>

                <br /><br />
                <div className="stat stat-info" style={{ width: '100%' }}>
                    <h6>Resource Controlled</h6>
                    {accessToken != null && (
                        <ManageResourceControlled
                            accessToken={accessToken}
                            dataProductId={dataProduct.dataProductId}
                            isResourceControlled = {dataProduct.isResourceControlled}
                            isDataProductAdmin={hasDataProductPermission}
                            updateDataProductState={(isResourceControlled: boolean) => setDataProduct({ ...dataProduct, isResourceControlled })}
                        />
                    )}
                </div>
            
                <br /><br />
                <div className="stat stat-info" style={{ width: '100%' }}>
                    <h6>Auto-Accept Consumers</h6>
                    {accessToken != null && (
                        <AutoAcceptForAllContracts
                            accessToken={accessToken}
                            dataProductId={dataProduct.dataProductId}
                            defaultToOpenContracts = {dataProduct.defaultToOpenContracts}
                            isDataProductAdmin={hasDataProductPermission}
                            updateDataProductState={(defaultToOpenContracts: boolean) => setDataProduct({ ...dataProduct, defaultToOpenContracts })}
                        />
                    )}
                </div>
                <br /><br />
                <div className="stat stat-info">

                    <h6>Contact Information</h6>
                    {accessToken != null && (
                        <ContactInformation
                            accessToken={accessToken}
                            dataProductId={dataProduct.dataProductId}
                            slack={dataProduct.support?.slack || null}
                            email={dataProduct.support?.email || null}
                            hasDataProductAdminPermission={hasDataProductPermission}
                            setDataProduct={setDataProduct}
                        />
                    )}

                </div>

                <br /><br />

                <div className="stat stat-default">
                    <h6>Created At</h6>
                    <span className="stat-value" style={{ color: colors.shale }}>
                        {new Date(createdAt).toLocaleString()}
                        <br />
                        <small>{createdBy}</small>
                    </span>
                </div>
                <br /><br />

                <div className="stat stat-default">
                    <h6>Updated At</h6>
                    <span className="stat-value" style={{ color: colors.shale }}>
                        {new Date(updatedAt).toLocaleString()}
                        <br />
                        <small>{updatedBy}</small>
                    </span>
                </div>
                <br /><br />

                <div className="stat stat-default">
                    <h6>Product Team</h6>
                    <span className="stat-value" style={{ color: colors.shale }}>
                        <div>
                            {productTeamStatus === "loading" && <Spinner size='small' />}
                            {productTeamStatus === "succeeded" && productTeam.map(m =>
                                <div key={m.canonicalId} style={{ marginRight: 12, marginBottom: 6, minWidth: 152 }}>
                                    {isValidUserInformation(m.coamInfo) && (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                            <img src={m.coamInfo.data.picture || getDefaultAvatar(m.canonicalId)} height={32} width={32} style={{ borderRadius: '50%' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: 1}}>
                                                <span>{m.coamInfo.data.fullName || m.canonicalId}</span>
                                                <small>{m.memberInfo.labels.join(', ')}</small>
                                            </div>
                                        </div>
                                    )}
                                    {isLoadingUserInformation(m.coamInfo) && (
                                        <div>
                                            <Spinner size='small' />
                                        </div>
                                    )}
                                    {isErrorUserInformation(m.coamInfo) && (
                                        <div>
                                            <span className='text-muted'>{m.coamInfo.errorMessage}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                            {productTeamStatus === "failed" && (
                                renderError(productTeamError.message)
                            )}
                        </div>
                    </span>
                </div>

            </div>
        </div>
    </div >;
};
