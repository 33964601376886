import React, { useState } from "react";
import {
    colors,
    Label,
    Pagination,
    Table,
    TextField,
    Tooltip,
} from "@cimpress/react-components";
import {
    DataPortalAPI,
    Interfaces,
} from "@cimpress-technology/data-portal-core";
import { MemberTableRow } from "./components/UserTableRow";
import { renderError } from "../../../../shared/Render";
import { AuthContext } from "../../../../../context/authContext";
import { NewUserModalButton } from "./components/NewUserModalButton";
import { useDataProductMembers } from "../../../../../hooks/useDataProductMembers";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { buildAlertMessageForProperty } from "../../../../shared/AlertMessage";

export const AccessManagement: React.FC<{
    dataProduct: DataPortalAPI.DataProduct;
    domain: DataPortalAPI.Domain;
    hasDataProductManagePermission: boolean; // Edit or Govern
}> = ({ dataProduct, domain, hasDataProductManagePermission }) => {
    return (
        <div>
            <h3>Access Management</h3>
            <small>
                Manage the users which have access to the Data Product. You can
                manage <b>Product Team</b> members here.
            </small>
            <hr />
            <br />
            <AccessManagementContent
                dataProduct={dataProduct}
                domain={domain}
                hasDataProductManagePermission={hasDataProductManagePermission}
            />
        </div>
    );
};

const AccessManagementContent: React.FC<{
    dataProduct: DataPortalAPI.DataProduct;
    domain: DataPortalAPI.Domain;
    hasDataProductManagePermission: boolean; // Edit or Govern permission
}> = ({ dataProduct, domain, hasDataProductManagePermission }) => {
    const { accessToken, profile } = React.useContext(AuthContext);

    const dataProductMembersHook = useDataProductMembers({
        accessToken,
        dataProductInfo: {
            dataProductId: dataProduct.dataProductId,
            adminCoamGroupId: dataProduct.adminCoamGroupId,
            readCoamGroupId: dataProduct.readCoamGroupId,
        },
    });

    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(0);
    const pageSize = 5;

    const currentUser =
        profile?.["https://claims.cimpress.io/canonical_id"].toLowerCase() ||
        "";
    const isCurrentUserAdmin =
        ((dataProductMembersHook.status === "succeeded" || dataProductMembersHook.status === "idle") &&
            dataProductMembersHook.membersMap[currentUser]?.isAdmin) ??
        false;

    const canManage = hasDataProductManagePermission || isCurrentUserAdmin;

    const filteredMembers = (() => {
        const members = ([...(dataProductMembersHook.members || [])]).map((o) =>
            Object.assign({}, o)
        );
        let result = [...members].sort((a, b) =>
            getCoamUserName(a.member).localeCompare(getCoamUserName(b.member))
        );
        if (searchText) {
            result = result.filter((d) =>
                getCoamUserName(d.member)
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            );
        }
        return result.slice(page * pageSize, page * pageSize + pageSize);
    })();

    return (
        <div>
            <div className="row">
                {dataProductMembersHook.status === "failed" && (
                    <div className="col">
                        {renderError(
                            "Error loading users",
                            dataProductMembersHook.error,
                            dataProductMembersHook.refresh
                        )}
                    </div>
                )}
                <div className="col-md-4">
                    <TextField
                        value={searchText}
                        placeholder="Search users"
                        onChange={(e) => {
                            setSearchText(e.target.value);
                            setPage(0);
                        }}
                    />
                </div>

                <div className="col-md-8">
                    <div style={{ float: "right" }}>
                        {accessToken &&
                            (dataProductMembersHook.status === "succeeded" || dataProductMembersHook.status === 'idle') && (
                                <NewUserModalButton
                                    accessToken={accessToken}
                                    disabled={!canManage}
                                    accountId={domain.accountId}
                                    dataProduct={dataProduct}
                                    dataProductId={dataProduct.dataProductId}
                                    existingMembers={
                                        new Set(
                                            Object.keys(
                                                dataProductMembersHook.membersMap
                                            )
                                        )
                                    }
                                    onUserAdded={
                                        dataProductMembersHook.addItemToMap
                                    }
                                />
                            )}
                        {dataProductMembersHook.status === "loading" && (
                            <Spinner size="small" />
                        )}
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginBottom: 8 }}>
                <div className="col-md-4">
                    {dataProductMembersHook.status === "succeeded" && (
                        <small style={{ color: colors.shale }}>
                            {dataProductMembersHook.members?.length} users
                        </small>
                    )}
                </div>
                <div
                    className="col-md-8"
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 4,
                    }}
                >
                    <Tooltip
                        direction="left"
                        contents={
                            <div>
                                <p>
                                    A title given to the member of the{" "}
                                    <b>Product Team</b>. Click on any of these
                                    labels to update it.
                                </p>
                            </div>
                        }
                    >
                        <Label status="info" text="Product Team Title" />
                    </Tooltip>
                    <Tooltip
                        direction="left"
                        contents={
                            <div>
                                <p>
                                    <b>Administrator of the Data Product:</b> A
                                    member that can edit the Data Product
                                    characteristics and owns the resources of
                                    the Data Product.
                                </p>
                            </div>
                        }
                    >
                        <Label status="success" text="Admin" />
                    </Tooltip>
                    <Tooltip
                        direction="left"
                        contents={
                            <div>
                                <p>
                                    <b>Reader of the Data Product:</b> A member
                                    that can access the resources of the Data
                                    Product.
                                </p>
                            </div>
                        }
                    >
                        <Label status="warning" text="Reader" />
                    </Tooltip>
                </div>
            </div>
            {(dataProductMembersHook.status === "succeeded" || dataProductMembersHook.status === 'idle') && (
                <Table
                    {...{ hasHover: true }}
                    columns={[
                        {
                            Header: "Data Product Members",
                            accessor: ".",
                            Cell: (row) => {
                                const memberInfo =
                                    dataProductMembersHook.membersMap[
                                        row.original.member.principal.toLowerCase()
                                    ];
                                return (
                                    <MemberTableRow
                                        accessToken={accessToken ?? ""}
                                        memberInfo={memberInfo}
                                        dataProductId={
                                            dataProduct.dataProductId
                                        }
                                        canManage={canManage}
                                        onUserDeleted={
                                            dataProductMembersHook.removeItemFromMap
                                        }
                                        disableDelete={
                                            dataProductMembersHook.members
                                                .length <= 1
                                        }
                                    />
                                );
                            },
                        },
                    ]}
                    data={filteredMembers}
                    noDataText="No members"
                />
            )}
            {dataProductMembersHook.status === "loading" && <Spinner />}
            <div className="row">
                <div className="col-md-12">
                    {(dataProductMembersHook.status === "succeeded" || dataProductMembersHook.status === 'idle') &&
                    (dataProductMembersHook.members.length || 0) > pageSize ? (
                        <div style={{ float: "right" }}>
                            <Pagination
                                pageRangeDisplayed={1}
                                marginPagesDisplayed={2}
                                pageCount={Math.ceil(
                                    dataProductMembersHook.members.length /
                                        pageSize
                                )}
                                onPageChange={(p) => setPage(p.selected)}
                                containerClassName={"pagination"}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <p className="text-muted">
                            After a user is added or deleted, it can take up to
                            one minute for the changes to be reflected in the
                            list of members. If your changes are not reflected
                            in the list, please refresh the page after 1 minute.
                        </p>
                        <p className="text-muted">
                            {buildAlertMessageForProperty("productTeam")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const getCoamUserName = (member: Interfaces.Coam.CoamGroupMember) => {
    return !member.is_client
        ? `${member.profile?.given_name} ${member.profile?.family_name}`
        : member.principal;
};
