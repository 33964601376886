import React, { useRef } from "react";
import { Modal } from "@cimpress/react-components";
import { Button } from "@cimpress/react-components";
import HtmlEditor from "../../../../shared/customFields/utils/TextEditor";
import { Editor as TinyMCE } from "tinymce";
import { renderError } from "../../../../shared/Render";
import { Spinner } from "@cimpress/react-components/lib/shapes";

type Props = {
    isLoading?: boolean;
    onUpdate: (content: string) => void;
    error?: Error | null;
    value: string;
    isOpen: boolean;
    onClose: () => void;
};

const DescriptionModal = (props: Props) => {
    const { isLoading, onUpdate, error, value, isOpen, onClose } = props;
    const editorRef = useRef<TinyMCE | null>(null);

    const onClickUpdate = () => {
        if (editorRef.current) {
            onUpdate(editorRef.current.getContent());
        }
    };

    return (
        <Modal
            status="info"
            show={isOpen}
            title="Description"
            onRequestHide={onClose}
            closeButton={true}
            closeOnOutsideClick={true}
            size="lg"
            footer={
                <div>
                    <Button
                        variant="outline-secondary"
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onClickUpdate}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <Spinner size="small" />
                        ) : (
                            "Update"
                        )}
                    </Button>
                </div>
            }
        >
            <HtmlEditor
                editorRef={editorRef}
                content={value}
                disabled={!!isLoading}
            />
            {!!error &&
                renderError(
                    "Error Occured while updating description of the data product", error
                )}
        </Modal>
    );
};
export default DescriptionModal;