import React, { ReactNode, useMemo, useState } from "react";
import {
    TextField,
    Table,
    Alert,
    Drawer,
    Button,
    Pagination,
} from "@cimpress/react-components";
import {
    DataProduct,
} from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import {
    DataPortalAPI
} from "@cimpress-technology/data-portal-core";
import { DatasetsGetCollectionItem } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { useAppDispatch, useAppSelector } from "../../../../../store/storeHooks";
import { setEditMode } from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/pdwDatasetsSlice";
import { AddPdwDatasetButton } from "./AddPdwDatasetButton";
import { ModalLink } from "./ModalLink";

function alphabeticalSortInPlace(datasets: DatasetsGetCollectionItem[]) {
    return datasets.sort((a, b) => a.name.localeCompare(b.name));
}

function filterBySearchText(datasets: DatasetsGetCollectionItem[], searchText: string): DatasetsGetCollectionItem[] {
    const lowercaseSearch = searchText ? searchText.toLowerCase() : null;
    return datasets.filter(
        (d) => lowercaseSearch == null ||
            d.name.toLowerCase().includes(lowercaseSearch) ||
            d.database.toLowerCase().includes(lowercaseSearch) ||
            d.schema.toLowerCase().includes(lowercaseSearch)
    );
}

function sortUserOwnedDatasets(
    datasets: DatasetsGetCollectionItem[],
    dataProductdomainId: string
): DatasetsGetCollectionItem[] {

    const availableDatasets: DatasetsGetCollectionItem[] = [];
    const datasetsWithNullDomain: DatasetsGetCollectionItem[] = [];
    const datasetsFromOtherDomain: DatasetsGetCollectionItem[] = [];
    const datasetsInOtherOrSameDataProduct: DatasetsGetCollectionItem[] = [];

    datasets.forEach((dataset) => {
        if (dataset.usedInDataProductId != null) {
            datasetsInOtherOrSameDataProduct.push(dataset);
            return;
        }
        if (dataset.domainId == null) {
            datasetsWithNullDomain.push(dataset);
            return;
        }
        if (dataset.domainId !== dataProductdomainId) {
            datasetsFromOtherDomain.push(dataset);
            return;
        }
        availableDatasets.push(dataset);
    });

    return [
        ...alphabeticalSortInPlace(availableDatasets),
        ...alphabeticalSortInPlace(datasetsWithNullDomain),
        ...alphabeticalSortInPlace(datasetsFromOtherDomain),
        ...alphabeticalSortInPlace(datasetsInOtherOrSameDataProduct)
    ];
}

export const UserOwnedDatasetsDrawer: React.FC<{
    dataProduct: DataProduct;
    domain: DataPortalAPI.Domain;
    includedDatasetIds: string[];
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
}> = ({
    dataProduct,
    domain,
    includedDatasetIds,
    hasDataProductAdminAccess,
    hasDataProductReadAccess,
}) => {
    // Drawer size to display
    const dispatch = useAppDispatch();
    const drawerSize = 0.55;

    const { userOwnedDatasetsState, editMode, pdwDatasetCollectionState } = useAppSelector(
        (state) => state.resourcesPdwDatasets
    );
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(0);
    const pageSize = 9;

    const filteredDatasets = useMemo(() => {
        if (userOwnedDatasetsState.status === "succeeded") {
            return sortUserOwnedDatasets(filterBySearchText(userOwnedDatasetsState.data || [], searchText), domain.domainId);
        }
        return [];
    }, [userOwnedDatasetsState, searchText, domain.domainId]);

    const databaseColumns = [
        {
            Header: "Action",
            accessor: ".",
            Cell: (row: { original: DatasetsGetCollectionItem } ) => {
                return (
                    <AddPdwDatasetButton
                        dataProductId={dataProduct.dataProductId}
                        usedInThisDataProductId={row.original.usedInDataProductId}
                        datasetId={row.original.id}
                        datasetDomainId={row.original.domainId}
                        dataProductDomainId={domain.domainId}
                        isIncludedInDataProduct={includedDatasetIds.includes(row.original.id)}
                    />
                ) as ReactNode;
            },
        },
        {
            Header: "Object",
            accessor: "name",
            Cell: (row: { original: DatasetsGetCollectionItem } ) => {
                return (
                    <ModalLink
                        datasetName={row.original.name}
                        datasetId={row.original.id}
                        hasDataProductReadAccess={hasDataProductReadAccess}
                        hasDataProductAdminAccess={hasDataProductAdminAccess}
                    />
                ) as ReactNode;
            },
        },
        {
            Header: "Database",
            accessor: "database",
        },
        {
            Header: "Schema",
            accessor: "schema",
        },
        {
            Header: "PDW",
            accessor: "cluster",
            Cell: (row) => {
                return row.original.cluster.split(".")[0];
            },
        },
        { Header: "Owner Role", accessor: "ownershipRole" },
    ];

    const datasetInfoAlert = (
        <Alert
            status="info"
            dismissible={true}
            style={{ paddingTop: 5, paddingBottom: 0, marginBottom: 15 }}
            message={
                <span>
                    The Datasets included:
                    <ul>
                        <li>
                            Appear in the list after up to <b>5 minutes</b> of
                            being created in Snowflake.
                        </li>
                        <li>
                            Are owned by a role that you own directly or indirectly in Snowflake or by the role{" "}
                            <a href={`https://console.river.cimpress.io/`}>
                                RIVER
                            </a>
                            {" "}when you are an admin of the River Stream that originates the dataset.
                        </li>
                    </ul>
                </span>
            }
        />
    );

    const onClose = () => {
        dispatch(setEditMode(false));
    };

    return (
        <>
            <Button
                className="pull-right"
                variant="primary"
                disabled={pdwDatasetCollectionState.status === 'loading'}
                onClick={() => {
                    dispatch(setEditMode(true));
                }}
            >
                + Add Datasets
            </Button>
            <Drawer
                show={editMode}
                onRequestHide={() => onClose()}
                size={drawerSize}
                closeOnClickOutside={true}
                footer={
                    <div className="col-xs-12">
                        <Button
                            className="pull-right"
                            onClick={() => onClose()}
                        >
                            Close
                        </Button>
                    </div>
                }
                bodyStyle={{ paddingBottom: 0 }}
            >
                {userOwnedDatasetsState.status === "idle" && (
                    <>
                        <p>Nothing is happening.</p>
                    </>
                )}
                {userOwnedDatasetsState.status === "loading" && (
                    <>
                        <Spinner />
                        <p>Loading datasets that you own...</p>
                    </>
                )}
                {userOwnedDatasetsState.status === "succeeded" && (
                    <>
                        <div
                            className="row"
                            style={{ marginTop: -20 }}
                        >
                            <div className="col-md-12">{datasetInfoAlert}</div>
                        </div>
                        <div style={{ maxWidth: "100%"}}>
                            <div className="row">
                                <div className="col-md-7">
                                    <h2 style={{ margin: 0, paddingLeft: 0 }}>
                                        My Datasets
                                    </h2>
                                    <small style={{ color: "grey" }}>
                                        sourced from{" "}
                                        <a
                                            href="https://data-discovery.cimpress.io"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Data Discovery
                                        </a>
                                    </small>
                                </div>
                                <TextField
                                    className="col-md-5 pull-right"
                                    value={searchText}
                                    placeholder={
                                        "Search for database schema or object"
                                    }
                                    helpText={`${filteredDatasets.length} Datasets found`}
                                    onChange={(e) => {
                                        setSearchText(e.target.value || "");
                                    }}
                                    style={{ margin: 0, padding: 0 }}
                                    disabled={false}
                                />
                            </div>
                            <Table
                                hasHover={true}
                                data={filteredDatasets.slice(page * pageSize, page * pageSize + pageSize)}
                                columns={databaseColumns}
                                noDataText={
                                    searchText != ""
                                        ? "No datasets available matching the search"
                                        : "No datasets available for this domain."
                                }
                            />
                            <div>
                                {(filteredDatasets.length || 0) > pageSize ? (
                                    <div style={{ float: "right" }}>
                                        <Pagination
                                            pageRangeDisplayed={1}
                                            marginPagesDisplayed={2}
                                            pageCount={Math.ceil(filteredDatasets.length / pageSize)}
                                            onPageChange={(p) => setPage(p.selected)}
                                            containerClassName={"pagination"}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </>
                )}
            </Drawer>
        </>
    );
};
