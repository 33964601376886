import React, { useState } from 'react';
import { Snackbar, Toggle , Modal, Button} from '@cimpress/react-components';
import { patchDataProductAttribute } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalApi';
import { AutoApproveContract } from '../../../../../interfaces/autoApproveContract';
import { OperationStatus } from '../../../../../interfaces/common';
import { parseUnknownErrorTypeToErrorMessage } from '@cimpress-technology/data-portal-core/lib/features/utils';


const modalContentOnEnable: AutoApproveContract = {
    description: `Enabling this will mark all future contracts created by the Data Portal as "open" meaning new consumers will be automatically approved.`,
    callToAction: "Do you want to continue?",
    cancelButtonText: "Decline",
    acceptButtonText: "Accept"
};

const modalContentOnDisable: AutoApproveContract = {
    description: `Disabling this will mark all future contracts created by the Data Portal as "private," meaning new consumers will require manual approval.`,
    callToAction: "Do you want to continue?",
    cancelButtonText: "Cancel",
    acceptButtonText: "Continue"
};

type Props = {
    accessToken: string;
    dataProductId: string;
    defaultToOpenContracts: boolean;
    isDataProductAdmin: boolean;
    updateDataProductState: (defaultToOpenContracts: boolean) => void;
}

export const AutoAcceptForAllContracts = (props:Props)=> {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [status, setStatus] = useState<OperationStatus>('idle');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState<AutoApproveContract | null>(null);
    const changeAutoAcceptForAllContracts = async (dataProductId: string, newDefaultToOpenContracts: boolean) => {
        setStatus('loading');
        try {
            await patchDataProductAttribute(props.accessToken, dataProductId, 'defaultToOpenContracts', newDefaultToOpenContracts);
            props.updateDataProductState(newDefaultToOpenContracts);
            setStatus('success');
        } catch (error) {
            setErrorMessage(parseUnknownErrorTypeToErrorMessage(error));
            setStatus('error');
        }
        setShowModal(false);
    };

    const onToggle = () => {
        setModalMessage(props.defaultToOpenContracts ? modalContentOnDisable : modalContentOnEnable);
        setShowModal(true);
    };

    return <>
        <div>
                <Modal
                    show={showModal}
                    title={"Confirmation Required: Auto Accept For All Contracts"}
                    onRequestHide={() => setShowModal(false)}
                    closeButton={status !== 'loading'}
                    footer={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                size="sm"
                                disabled={status === 'loading'}
                                onClick={()=> setShowModal(false)}
                            >

                                {modalMessage && (
                                    modalMessage.cancelButtonText
                                )}

                            </Button>
                            <Button
                                size="sm"
                                variant='primary'
                                disabled={status === 'loading'}
                                onClick= {()=> changeAutoAcceptForAllContracts(props.dataProductId, !props.defaultToOpenContracts)}
                            >
                                {modalMessage && (
                                    modalMessage.acceptButtonText
                                )}
                            </Button>
                        </div>
                    }
                >
                    <div>
                        {modalMessage && (
                            <p>
                            {modalMessage.description}
                         <br /><br />
                            <b>{modalMessage.callToAction}</b>
                        </p>
                        )}
                    </div>
                </Modal>
                <Toggle
                    on={props.defaultToOpenContracts}
                    size="sm"
                    disabled={status === 'loading'}
                    onClick={onToggle}
                />
        </div>
        <Snackbar status='danger' show={status === 'error'} delay={4000} onHideSnackbar={() => { setErrorMessage(null); setStatus('idle');}} >
            <div>{errorMessage}</div>
        </Snackbar>
    </>;
};
