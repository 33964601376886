import React from 'react';
import { Modal, Button } from "@cimpress/react-components";

interface ConfirmationAlertProps {
    show: boolean;
    message: JSX.Element;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationAlert: React.FC<ConfirmationAlertProps> = ({ show, message, onConfirm, onCancel }) => {
    return (
        <Modal
            show={show}
            onRequestHide={onCancel}
            footer={
                <div>
                    <Button variant="secondary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={onConfirm}>
                        Confirm
                    </Button>

                </div>
            }
            title="Confirmation"
        >
            {message}
        </Modal>
    );
};

export default ConfirmationAlert;