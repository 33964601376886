import React from "react";
import { IconAlertTriangle } from "@cimpress-technology/react-streamline-icons/lib";
import { colors } from "@cimpress/react-components";

export function buildAlertMessageForProperty(property: 'domain' | 'productTeam' | 'slackChannel' | 'email') {
    const propertyToMetadataPropertyNameMap: Record<typeof property, string> = {
        domain: 'Domain',
        productTeam: 'Product Team',
        slackChannel: 'Slack Channel',
        email: 'Data Owner',
    };
    const metadataPropertyName = propertyToMetadataPropertyNameMap[property];
    switch (property) {
        case 'productTeam':
            return `Adding or Removing users from the Product Team will also affect the '${metadataPropertyName}' property of all PDW Datasets linked to this Data Product.`;
        case 'slackChannel':
        case 'email':
        case 'domain':
        default:
            return `This change will override the ${metadataPropertyName} property in all PDW Datasets linked to this Data Product.`;
    }
}

const AlertMessage = (props: { message: string }) => {
    return (
        <div className="alert-message">
            <IconAlertTriangle
                weight="fill"
                size="sm"
                style={{ color: colors.warning.base }}
            />
            <span>
                {props.message}
            </span>
        </div>
    );
};

export default AlertMessage;
