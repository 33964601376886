import React from "react";
import { InlineEdit, Snackbar, colors } from "@cimpress/react-components";
import { SliceState } from "../../../../../features/common";
import { patchDataProductAttribute } from "@cimpress-technology/data-portal-core/lib/clients/dataPortalApi";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import IconAlertTriangle from "@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle";
import { persimmon } from "@cimpress/react-components/lib/colors";
import { ExtendedDataProduct } from "../../../../../clients/dataPortalApi";
import AlertMessage, { buildAlertMessageForProperty } from "../../../../shared/AlertMessage";

type Props = {
    accessToken: string;
    dataProductId: string;
    slack: string | null;
    email: string | null;
    hasDataProductAdminPermission: boolean;
    setDataProduct: React.Dispatch<
        React.SetStateAction<ExtendedDataProduct | null>
    >;
};

type FormState = {
    slackStatus: SliceState;
    emailStatus: SliceState;
    slackCurrent: string | null;
    emailCurrent: string | null;
    slackErrorOnSave: string | null;
    emailErrorOnSave: string | null;
    isEditingSlack: boolean;
    isEditingEmail: boolean;
};

const ItemStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 6,
};

const ContactInformation = (props: Props) => {
    const [formState, setFormState] = React.useState<FormState>({
        slackStatus: "idle",
        emailStatus: "idle",
        slackCurrent: props.slack,
        emailCurrent: props.email,
        slackErrorOnSave: null,
        emailErrorOnSave: null,
        isEditingSlack: false,
        isEditingEmail: false,
    });

    const patchSupportAttribute = async (
        attribute: "slack" | "email",
        value: string
    ) => {
        setFormState((prevState) => ({
            ...prevState,
            [attribute + "Status"]: "loading",
            [attribute + "ErrorOnSave"]: null,
        }));
        try {
            await patchDataProductAttribute(
                props.accessToken,
                props.dataProductId,
                `support/${attribute}`,
                value
            );
            setFormState((prevState) => ({
                ...prevState,
                [attribute + "Status"]: "succeeded",
                [attribute + "Current"]: value,
            }));
        } catch (e) {
            setFormState((prevState) => ({
                ...prevState,
                [attribute + "Status"]: "failed",
                [attribute + "ErrorOnSave"]:
                    parseUnknownErrorTypeToErrorMessage(e),
            }));
        }
    };

    return (
        <>
            <div
                className="stat-value"
                style={{ display: "flex", flexDirection: "column", gap: 8 }}
            >
                <div>
                    {!props.hasDataProductAdminPermission && (
                        <div style={ItemStyle}>
                            <span style={{ color: colors.shale }}>Slack: </span>
                            <span
                                className={
                                    props.slack == null ? "text-muted" : ""
                                }
                            >
                                {props.slack == null
                                    ? "No Slack channel provided"
                                    : props.slack}
                            </span>
                        </div>
                    )}
                    {props.hasDataProductAdminPermission &&
                        (formState.slackErrorOnSave ? (
                            <IconAlertTriangle
                                color={persimmon.base}
                                size="lg"
                                weight="fill"
                            />
                        ) : (
                            <>
                                <InlineEdit
                                    name="editSlack"
                                    value={formState.slackCurrent || ""}
                                    label="Slack"
                                    placeholder="Enter Slack channel"
                                    onEditStateChange={(e) => {
                                        setFormState((prevState) => ({
                                            ...prevState,
                                            isEditingSlack: e,
                                        }));
                                    }}
                                    onSave={(e) =>
                                        patchSupportAttribute(
                                            "slack",
                                            e.value || ""
                                        ).then(() => {
                                            props.setDataProduct((prevState) => {
                                                if (prevState == null) {
                                                    return null;
                                                }
                                                return {
                                                    ...prevState,
                                                    support: {
                                                        ...prevState.support,
                                                        slack: e.value || "",
                                                    }
                                                };
                                            });
                                        })
                                    }
                                    disabled={formState.slackStatus === "loading"}
                                    style={{ marginBottom: 4 }}
                                />
                                {formState.isEditingSlack === true && <AlertMessage message={buildAlertMessageForProperty('slackChannel')} />}
                            </>
                        ))}
                </div>
                <div>
                    {!props.hasDataProductAdminPermission && (
                        <div style={ItemStyle}>
                            <span style={{ color: colors.shale }}>Email: </span>
                            <span
                                className={
                                    props.email == null ? "text-muted" : ""
                                }
                            >
                                {props.email == null
                                    ? "No contact email provided"
                                    : props.email}
                            </span>
                        </div>
                    )}
                    {props.hasDataProductAdminPermission &&
                        (formState.emailErrorOnSave ? (
                            <IconAlertTriangle
                                color={persimmon.base}
                                size="lg"
                                weight="fill"
                            />
                        ) : (
                            <>
                                <InlineEdit
                                    name="editEmail"
                                    value={formState.emailCurrent || ""}
                                    label="Email"
                                    placeholder="Enter Email"
                                    onEditStateChange={(e) => {
                                        setFormState((prevState) => ({
                                            ...prevState,
                                            isEditingEmail: e,
                                        }));
                                    }}
                                    onSave={(e) =>
                                        patchSupportAttribute(
                                            "email",
                                            e.value || ""
                                        ).then(() => {
                                            props.setDataProduct((prevState) => {
                                                if (prevState == null) {
                                                    return null;
                                                }
                                                return {
                                                    ...prevState,
                                                    support: {
                                                        ...prevState.support,
                                                        email: e.value || "",
                                                    }
                                                };
                                            });
                                        })
                                    }
                                    disabled={formState.emailStatus === "loading"}
                                    style={{ marginBottom: 4 }}
                                />
                                {formState.isEditingEmail === true && <AlertMessage message={buildAlertMessageForProperty('email')} />}
                            </>
                        ))}
                </div>
            </div>
            <Snackbar
                status="danger"
                show={formState.emailErrorOnSave != null}
                delay={4000}
                onHideSnackbar={() =>
                    setFormState((prevState) => ({
                        ...prevState,
                        emailErrorOnSave: null,
                        emailStatus: "idle",
                    }))
                }
            >
                <div>{formState.emailErrorOnSave}</div>
            </Snackbar>
            <Snackbar
                status="danger"
                show={formState.slackErrorOnSave != null}
                delay={4000}
                onHideSnackbar={() =>
                    setFormState((prevState) => ({
                        ...prevState,
                        slackErrorOnSave: null,
                        slackStatus: "idle",
                    }))
                }
            >
                <div>{formState.slackErrorOnSave}</div>
            </Snackbar>
        </>
    );
};

export default ContactInformation;
