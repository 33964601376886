import React from "react";
import { CustomField } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import SanitizeHtmlContent from "../../common/SanitizeHtmlContent";

type Props = { customFieldId: string; customField: CustomField };

const DataProductCustomFieldView = (props: Props) => {
    return (
        <div key={props.customFieldId}>
            <h5>{props.customField.displayName}</h5>
            <SanitizeHtmlContent value={props.customField.value} section="custom-fields" sanitizeData={false} />
        </div>
    );
};

export default DataProductCustomFieldView;
