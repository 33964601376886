import React, { useEffect } from 'react';
import IconImageFileSettings from '@cimpress-technology/react-streamline-icons/lib/IconImageFileSettings';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import { fetchDataProductCustomFields } from '@cimpress-technology/data-portal-core/lib/features/customFields/common';
import SanitizeHtmlContent from '../../../shared/common/SanitizeHtmlContent';

type Props = { accessToken?: string, dataProductId?: string };

/**
 * Also known as `Additional Information`
 * @param props
 * @returns
 */
const CustomFields = (props: Props) => {
    const dispatch = useAppDispatch();
    const { status, customFields, error } = useAppSelector(state => state.customFields);

    useEffect(() => {
        if (props.accessToken && props.dataProductId) {
            dispatch(fetchDataProductCustomFields({ accessToken: props.accessToken, dataProductId: props.dataProductId }));
        }
    }, [props.accessToken, props.dataProductId, dispatch]);

    return (
        <div className="dp-card summary__custom-fields">
            <div className="summary__card__header">
                <span className='summary__card__header__icon'><IconImageFileSettings /></span>
                <span className='summary__card__header__text'>Additional Information</span>
            </div>
            <div className="summary__card__body">
                <div className="summary__custom-fields__items">
                    {status === 'loading' ? <Spinner size='small' /> : null}
                    {status === 'failed' ? <div><small className='text-muted'>{error?.message}</small></div> : null}
                    {status === 'succeeded' && Object.keys(customFields || {}).length === 0 ? <div><small className='text-muted'>There is no additional information</small></div> : null}
                    {status === 'succeeded' && customFields && Object.keys(customFields).map((key) => (
                        <div key={key} className="summary__custom-fields__item">
                            <span className="summary__custom-fields__item__name">{customFields[key].displayName}</span>
                            <SanitizeHtmlContent
                                value={customFields[key].value}
                                section='custom-fields'
                                sanitizeData={false}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomFields;